<template>
  <div class="chat-preview">
    <img src="../../../../../common/images/custom-tab/doc-bg-new-h5.png" />
  </div>
</template>
<script>
  export default {
    name: 'component-doc'
  };
</script>
<style lang="less" scoped>
  .chat-preview {
    width: 286px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
</style>
